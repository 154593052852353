import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import './Popup.css'; // Import CSS for styling
import download1 from '../src/download1.JPG';

const Popup = ({ onClose }) => {

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        
        <img src={download1} style={{ maxWidth: '60%', marginLeft: '0%', maxHeight: '60%', marginTop: '2%' }} />
        <button onClick={onClose} className="popup-close">X</button>
      </div>
    </div>
  );
};

export default Popup;

