import React, { useState } from 'react';
import axios from 'axios';

const FaceSwapping = ({ userImageFile, targetImageUrl }) => {
  const [resultImage, setResultImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const handleFaceSwap = async () => {
    if (!userImageFile || !targetImageUrl) {
      setError('Please upload both user and target images.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const userImageBase64 = await fileToBase64(userImageFile);
      const targetImageBase64 = await urlToBase64(targetImageUrl);

      const data = {
        "input_face_image": userImageBase64,
        "target_face_image": targetImageBase64,
        "file_type": "jpg",
        "face_restore": true
      };

      const response = await axios.post(
        'https://api.segmind.com/v1/sd2.1-faceswapper',
        data,
        {
          headers: {
            'x-api-key': 'SG_f7b25529f53cba59',
            'Content-Type': 'application/json'
          },
          responseType: 'arraybuffer'
        }
      );

      const contentType = response.headers['content-type'];
      if (!contentType || !contentType.startsWith('image/jpeg')) {
        throw new Error('Unexpected response content type: ' + contentType);
      }

      const base64Image = arrayBufferToBase64(response.data);
      const imageUrl = `data:image/jpeg;base64,${base64Image}`;

      setResultImage(imageUrl);
      setShowModal(true); // Open modal when image is ready
    } catch (error) {
      console.error('Error processing image:', error.response ? error.response.data : error.message);
      setError('Error processing the image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const urlToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.arrayBuffer())
        .then(buffer => {
          const base64 = arrayBufferToBase64(buffer);
          resolve(base64);
        })
        .catch(reject);
    });
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  return (
    <div className="result-container">
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleFaceSwap} disabled={loading}>
        {loading ? 'Swapping...' : 'Swap Styles'}
      </button>

      {/* Modal Popup */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <img src={resultImage} alt="Swapped Result" />
          </div>
        </div>
      )}
    </div>
  );
};

export default FaceSwapping;



